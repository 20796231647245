/* You can add global styles to this file, and also import other style files */
/* .preloader {
    display: flex !important;
} */

.only-lock {
    opacity: 0.7;
}

.py-20 {
    padding: 20px 0;
}

.main {
    min-height: 300px !important;
}

.navbar-brand img, .sidebar-logo img, .footer-logo img {
    height: 65px !important;
    width: auto !important;
}

.hero-single {
    height: 600px !important;
    background-size: 100% 600px !important;
}

.hero-single::before {
    background: none !important;
}

@media (min-width: 992px) {
    .fivecolumns {
        width: 20%;
        /* *width: 20%; */
    }
}

.cta-wrapper .about-list li, .cta-wrapper .about-list li::before {
    color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.counter-box .icon {
    background: #EC4D47;
}

.pt-25 {
    padding-top: 25px !important;
}

.nice-select .list {
    max-height: 210px;
    overflow-y: scroll;
}

.alert-fixed {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 9999;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .pt-25 {
        padding-top: 45px !important;
    }

    #scroll-top.active {
        bottom: 100px;
    }

    .hero-single .hero-content .hero-title, .site-title {
        font-size: 30px;
    }

    .app-download .download-wrapper {
        padding-left: 30px !important;
        padding-top: 55px !important;
    }
}

@media (max-width: 992px) {
    .header-top {
        display: block;
    }

    .header-top-left, .header-top-social, .sidebar-btn {
        display: none;
    }

    .navbar {
        top: auto;
    }

    .navbar.fixed-top {
        top: 0;
    }

    .main {
        margin-top: 3rem;
    }

    .navbar-collapse {
        padding-bottom: 20px;
    }

    .header-top-link {
        margin: auto 20%;
        width: 100%;     
    }

    .nav-right, .navbar .nav-right-btn {
        display: block;
    }
}

/* .business-category, .category-item {
    background: #facecd;
    background: #dee2e6;
} */

.flex-control-thumbs img {
    height: 125px;
}

.pagination {
    flex-wrap: wrap;
}

.navbar {
    z-index: 2;
}

.navbar .position-relative {
    z-index: 0;
}

.owl-carousel {
    z-index: 0;
}

.header {
    position: relative;
    z-index: 3;
}

.header .header-top {
    z-index: 3;
}